// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(document).ready(function () {
    jQuery(".herosilder").slick({
        dots:false,
        infinite: true,
        arrows:false,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll:1,
        autoplaySpeed: 8000,
        speed: 800,
        fade: true
    });

    jQuery(".regular").slick({
        dots:false,
        infinite: true,
        arrows:false,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll:1
    });

    jQuery(".aboutsilder").slick({
        dots:false,
        infinite: true,
        arrows:true,
        autoplay:true,
        slidesToShow: 1,
        slidesToScroll:1
    });

});
